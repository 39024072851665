<template>
  <div class="step-component first-step">
    <div style="width: 530px; margin: 0 auto;overflow:hidden">
      <div class="row mb-3 text-center">
        <div class="col-sm-4 mr-1 text-right" style="padding-top:10px">合同编号</div>
        <div class="col-sm-6">
          <input
            type="text"
            v-model="contractNo"
            placeholder="请输入"
            class="custom-select"
          />
        </div>
      </div>
      <div class="row mb-3 text-center">
        <div class="col-sm-4 mr-1 text-right" style="padding-top:5px">合同开始时间</div>
        <div class="col-sm-6">
          <el-date-picker
            class="mr-3"
            v-model="startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请输入"
            :editable="false"
            prefix-icon="el-icon-time"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-sm-4 mr-1 text-right" style="padding-top:5px">合同结束时间</div>
        <div class="col-sm-6">
          <el-date-picker
            class="mr-3"
            v-model="endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请输入"
            :editable="false"
            prefix-icon="el-icon-time"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    initDataVal: Object,
  },
  name: "ContractFirstStep",
  data() {
    return {
      contractNo: "",
      startDate: "",
      endDate: "",
    };
  },
  watch: {
    initDataVal: {
      handler: function(val) {
        for (let key in val) {
          if (val.hasOwnProperty(key) && this.hasOwnProperty(key)) {
            this[key] = val[key];
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    getData() {
      const { contractNo, startDate, endDate } = this;
      return { contractNo, startDate, endDate };
    },
  },
};
</script>

<style>
.first-step {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%) translateY(-50%);
}

.contract-modal .custom-select {
  width: 220px;
  margin-right: 18px;
  margin-top: 5px;
  font-size: 24px;
  background: #fff;
}
.col-sm-6 {
  margin-top: 3px;
}
.custom-select {
  /* color: #999999; */
  border: none;
}
input::-webkit-input-placeholder {
  color: #999999 !important;
}
.cs-style .custom-select,
.cs-style .custom-input {
  border: 1px solid #999 !important;
}
.text-right {
  color: #000;
}

.mr-3 input{
  border-color: #999999 !important;
}
</style>
